<template>
  <div class="text-center">

    <v-dialog
        v-model="successDialog"
        hide-overlay
        persistent
        fullscreen
    >
      <v-card
          color="white"
          elevation="0"
          align="center"

      >
        <v-row

            no-gutters



        >

           <div class="Image"  >
             <v-col>

               <v-img

                    class="mb-11"
                   max-height="1000"
                   max-width="250"
                   :src="require('@/assets/svg/success.svg')"
               ></v-img>
               <div class="pb-10">{{title}}</div>
               <v-progress-circular
                   :size="40"
                   color="primary"
                   indeterminate
               ></v-progress-circular>
             </v-col>
           </div>

          </v-row>
        <v-card-text>



        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import store from "@/store";
import router from "@/router";
export default {

  props:{
    title:String,
    successDialog: {
      default: false
    },
  },
  components:{
    Loading
  },
  mounted() {

    setTimeout(() => {
      this.close();
    }, 3000);
  },
  methods:{
    close() {
      this.$emit('update:successDialog', false);
    },
  }

}
</script>

<style scoped>

.Image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  font-size: 2rem;
  padding-top: 7%;
  font-weight: 600;
}
</style>