<template>
  <div >

    <v-row justify="center" >
      <v-dialog
          v-model="qrDialog"

          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >

        <v-card>
          <v-toolbar
              dark
              color="primary"
          >

            <v-btn
                icon
                dark
                @click.native="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>


            <v-toolbar-title >{{itemSelected.name}}</v-toolbar-title>
            <v-spacer></v-spacer>



          </v-toolbar>
          <Loading v-if="isloading"/>
          <v-container v-else style="height: 100%;" >
            <div class="Packages">
              <VCol  cols="12" md="8"
                     xl="4">

                <v-card elevation="5"  class=" rounded-xl">

                  <v-row style="width: 100%;" justify="end">

                    <v-btn
                        v-if="$store.getters.hasDeletePermission('group')"
                        icon
                        color="blue"
                        class="pa-10"
                        @click="deleteItem()"
                    >
                      <v-icon size="30" color="red" class="ma-5">mdi-delete</v-icon>
                    </v-btn>


                  </v-row>
                  <RowCardView title="اسم المجوعة" :body="itemSelected.name"/>

                  <v-divider/>
                  <v-divider/>
                  <RowCardView title="الجهاز" :body="itemSelected.device"/>
                  <v-divider/>
                  <RowCardView title="رقم الهاتف" :body="itemSelected.phone"/>
                  <v-divider/>

                  <RowCardView title="الترميز" :body="itemSelected.code"/>

                  <v-divider/>
                  <RowCardView title="حالة الترميز" :body="itemSelected.status?'فعال':'غير فعال'"/>
                  <v-divider/>
                  <RowCardView title="رقم المجموعة" :body="itemSelected.id"/>


                </v-card>

              </VCol>


            </div>
            <DeleteItemDialog :url-name="'/group/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="itemToken"/>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </div>

</template>

<script>

import Loading from '@/components/Loading.vue';

import RowCardView from "@/components/RowCardView";
import DeleteItemDialog from "@/components/dialogs/DeleteItemDialog";


export default {
  props:{

    qrDialog: {
      default: false
    },
    itemToken:String,
    itemSelected:{}

  },
  components: {
    Loading,
    RowCardView,
    DeleteItemDialog,


  },
  data () {
    return {
      deleteController:false,
      isErrorQr:false,
      isQrLoading:true,
      logsItems:[],
      isFristGen:true,
      socket:null,
      itemTemp:{},
      qrcodescr:"",


      isloading: false,

    }
  },
  methods: {
    close() {
      this.$emit('update:qrDialog', false);
    },
   async deleteItem() {
      this.deleteController =true;
    },
  },
  mounted() {
    this.$root.$on('closeGroupView', () => {
      this.close();
    });
  }

}
</script>

<style scoped>



.Packages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}
</style>