<template>
    <VCard elevation="0" >
      <VCol>

        <VRow class="px-7 py-4">
          <div  class="title">
            {{title}}
          </div>


          <div class="body" >
            {{body}}
          </div>

        </VRow>



      </VCol>
    </VCard>

</template>

<script>
export default {
    props: ["title","body"]

}
</script>

<style scoped>
  .title{
    font-size: 30px;
    color: rgb(167, 167, 167);
    font-weight: 400;
    width: 35%;
  }
  .body{
    font-size: 20px;

    width: 65%;

  }
</style>